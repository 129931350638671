<template>
  <div id="container" v-if="fetch_data">
    <section id="event">
      <div class="wrap">
        <h2 class="main_ttl"><span>{{ fetch_data.title }}</span></h2>
        <div class="contents" v-html="fetch_data.lead_text"></div>

        <div class="cts_box">
          <h3 class="sub_ttl"><span>スケジュール</span></h3>
          <ul class="event_list">
            <li class="flex" v-for="model in fetch_data.workshops" :key="model.id">
              <div class="info">
                <div class="class">
                  <span class="tag class01" v-if="model.rank_regular_plus">本科プラス</span>
                  <span class="tag class02" v-if="model.rank_regular">本科</span>
                  <span class="tag class03" v-if="model.rank_preparatory">予科</span>
                  <span class="tag class04" v-if="model.rank_general">一般</span>
                  <span class="tag class05" v-if="model.rank_student">学生科</span>
                  <span class="tag class06" v-if="false /*model.rank_corporation*/">法人科</span>
                </div>
                <h3>{{ model.title }}</h3>
                <p v-html="model.list_text"></p>
                <div class="date"><span class="day">{{ moment(model.open_date, 'YYYY/MM/DD (ddd)') }}</span><span
                    class="time">{{ model.open_time }}</span></div>

                <div v-if="model.category === 'category_camp_tour' || model.category === 'category_camp_camp'">
                  <div class="detail flex" v-if="model.offline"><span class="tag">開催地</span>{{ model.place }}</div>
                  <div class="detail flex" v-if="model.online"><span class="tag red">LIVE</span>オンライン</div>
                </div>
                <div v-else>
                  <div class="detail flex" v-if="model.offline"><span class="tag">教室</span>{{ model.place }}</div>
                  <div class="detail flex" v-if="model.online"><span class="tag red">LIVE</span>オンライン</div>
                </div>
              </div>
              <div class="btn">
                <router-link :to="{ name: 'WorkshopShow', params: {category: category, id: model.id} }"
                             class="linkBtn light">詳細・申し込み
                </router-link>
              </div>
            </li>

          </ul>
          <!--pager-->
          <div class="pager flex" v-if="false">
            <div class="btn prev"><a href=""></a></div>
            <div class="btn"><a href="">1</a></div>
            <div class="btn current">2</div>
            <div class="btn"><a href="">3</a></div>
            <div class="btn dotted"></div>
            <div class="btn"><a href="">10</a></div>
            <div class="btn next"><a href=""></a></div>
          </div>
          <!--//pager-->
        </div>
        <!--非塾生には表示されない？-->
        <div class="cts_box" v-if="false">
          <div class="btn_wrap">
            <p class="event_caution">
              終了した研修会を確認するには<br class="sp">こちらのボタンを押してください。<br>
              （参加した研修会の履歴を確認できます）
            </p>
            <router-link :to="{ name: 'UsersCustomerJoinEvents'}" class="submit basic arrow">研修会参加履歴</router-link>
          </div>
        </div>
        <!--//非塾生には表示されない？-->

        <div class="cts_box" v-if="fetch_data.closed_workshops.length > 0 && fetch_data.category === 'category_workshop_irmtg'">
          <h3 class="sub_ttl"><span>開催実績</span></h3>
          <table class="type01 ir_archives">
            <tbody>
            <tr class="header">
              <th class="date">日</th>
              <th class="ir_guest">ゲスト</th>
              <th class="ir_color">会社の特色</th>
            </tr>
            <tr v-for="model in fetch_data.closed_workshops" :key="model.id">
              <td class="date">
                <div>{{ moment(model.open_date, 'YYYY/MM/DD (ddd)') }}</div>
              </td>
              <td class="ir_guest"><h3 class="sp">ゲスト</h3>
                <div v-if="model.url">
                  <a v-bind:href="model.url" target="_blank" v-html="model.guest"></a>
                </div>
                <div v-else v-html="model.guest"></div>
              </td>
              <td class="ir_color"><h3 class="sp">会社の特色</h3>
                <div v-html="model.company_info"></div>
              </td>
            </tr>
            </tbody>
          </table>
          <!--pager-->
          <div class="pager flex" v-if="false">
            <div class="btn prev"><a href=""></a></div>
            <div class="btn"><a href="">1</a></div>
            <div class="btn current">2</div>
            <div class="btn"><a href="">3</a></div>
            <div class="btn dotted"></div>
            <div class="btn"><a href="">10</a></div>
            <div class="btn next"><a href=""></a></div>
          </div>
          <!--//pager-->
        </div>

        <div class="cts_box" v-if="fetch_data.closed_workshops.length > 0 && (fetch_data.category === 'category_camp_camp' || fetch_data.category === 'category_camp_tour')">
          <h3 class="sub_ttl"><span>開催実績</span></h3>
          <ul class="event_list">
            <li class="flex" v-for="model in fetch_data.closed_workshops" :key="model.id">
              <div class="info">
                <div class="class">
                  <span class="tag class01" v-if="model.rank_regular_plus">本科プラス</span>
                  <span class="tag class02" v-if="model.rank_regular">本科</span>
                  <span class="tag class03" v-if="model.rank_preparatory">予科</span>
                  <span class="tag class04" v-if="model.rank_general">一般</span>
                  <span class="tag class05" v-if="model.rank_student">学生科</span>
                  <span class="tag class06" v-if="false /*model.rank_corporation*/">法人科</span>
                </div>
                <h3>{{ model.title }}</h3>
                <p v-html="model.list_text"></p>
                <div class="date"><span class="day">{{ moment(model.open_date, 'YYYY/MM/DD (ddd)') }}</span><span
                    class="time">{{ model.open_time }}</span></div>

                <div v-if="model.category === 'category_camp_tour' || model.category === 'category_camp_camp'">
                  <div class="detail flex" v-if="model.offline"><span class="tag">開催地</span>{{ model.place }}</div>
                  <div class="detail flex" v-if="model.online"><span class="tag red">LIVE</span>オンライン</div>
                </div>
                <div v-else>
                  <div class="detail flex" v-if="model.offline"><span class="tag">教室</span>{{ model.place }}</div>
                  <div class="detail flex" v-if="model.online"><span class="tag red">LIVE</span>オンライン</div>
                </div>
              </div>
              <div class="btn">
                <router-link :to="{ name: 'WorkshopShow', params: {category: category, id: model.id} }"
                             class="linkBtn light skeleton">詳細
                </router-link>
              </div>
            </li>
          </ul>
          <!--pager-->
          <div class="pager flex" v-if="false">
            <div class="btn prev"><a href=""></a></div>
            <div class="btn"><a href="">1</a></div>
            <div class="btn current">2</div>
            <div class="btn"><a href="">3</a></div>
            <div class="btn dotted"></div>
            <div class="btn"><a href="">10</a></div>
            <div class="btn next"><a href=""></a></div>
          </div>
          <!--//pager-->
        </div>

      </div>
    </section>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  components: {},
  data() {
    return {
      category: this.$route.params.category,
      fetch_data: null,
    }
  },
  watch: {
    '$route'() {
      this.fetch_data = null
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.category = this.$route.params.category
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}workshop.json`, {
            params: {
              draft: this.$route.query.draft,
              category: this.category
            }
          })
          .then(response => {
            this.fetch_data = response.data
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    }
  }
}
</script>
